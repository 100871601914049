import React from 'react'
import PropTypes from 'prop-types'
import { StyledBreadCrumb } from './styles'

const BreadCrumb = (props) => {
  const { msg } = props
  return (
    <StyledBreadCrumb>
      <h1>{msg}</h1>
    </StyledBreadCrumb>
  )
}

BreadCrumb.propTypes = {
  msg: PropTypes.string.isRequired
}

export default BreadCrumb
