import React from 'react'
import BreadCrumb from '@/utils/Breadcrumb'
import Container from '@/utils/Container'
import { clientesAlbum } from '@/utils/AlbumsPhotos'
import { StyledContainer } from './styles'

const Clients = () => {
  const renderClients = (item, index) => (
    <div key={index}>
      <img src={item.url} />
    </div>
  )

  return (
    <Container>
      <BreadCrumb msg="Clientes" />
      <StyledContainer className='squad'>
        <div className='content-clients'>
          {clientesAlbum.map((el, index) => renderClients(el, index))}
        </div>
      </StyledContainer>
    </Container>
  )
}

export default Clients
