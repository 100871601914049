import styled from '@emotion/styled'

export const StyledContainer = styled.section`
  div {
    &.content-clients {
      display: grid;
      padding: 30px;

      & > div {
        padding: 30px;
        display: flex;
        align-items: center;
        box-shadow: 1px 1px var(--color-secondary), -1px -1px var(--color-secondary);
      
        img {
          width: 100%;
          max-width: 150px;
          max-height: 150px;
          margin: 0 auto;
        }
      }
    }

    @media (min-width: 580px) {
      grid-template-columns: repeat(2, 50%);
    }

    @media (min-width: 780px) {
      grid-template-columns: repeat(3, 33.33333%);
    }

    @media (min-width: 1024px) {
      grid-template-columns: repeat(4, 25%);
    }

    @media (min-width: 1333px) {
      grid-template-columns: repeat(5, 20%);

      &.content-clients {
        padding: 30px 0;
      }
    }
  }

  &.squad {
    max-width: 1333px;
    margin: 0 auto;
  }
`
