import React from 'react'
import PropTypes from 'prop-types'
import { StyledContainer } from './styles'

const Container = (props) => {
  const { children, fullHeight } = props
  return (
    <StyledContainer className={fullHeight ? 'full-height' : ''}>
      {children}
    </StyledContainer>
  )
}

Container.propTypes = {
  children: PropTypes.any.isRequired,
  fullHeight: PropTypes.bool
}

Container.defaultProps = {
  fullHeight: false
}

export default Container
