import React from 'react'
import { useParams } from 'react-router-dom'
import Container from '@/utils/Container'
import { urlRoutes } from '@/components/Menu/components/menuItems'
import BlindPlus from './pages/BlindPlus'
import BlindSuper from './pages/BlindSuper'
import BlindGlass from './pages/BlindGlass'
import PortaDeslizante from './pages/PortaDeslizante'
import PortaDupla from './pages/PortaDupla'
import PortaPivotante from './pages/PortaPivotante'
import Cofres from './pages/Cofres'
import PassaVolumes from './pages/PassaVolumes'
import PortaCofre from './pages/PortaCofre'
import OutrosProjetos from './pages/OutrosProjetos'
import { StyledH2 } from '../Company/pages/styles'

const DefaultPage = () => {
  const params = useParams()

  const renderPage = () => {
    switch (params.id) {
      case urlRoutes.blindPlus:
        return (<BlindPlus />)
      case urlRoutes.blindSuper:
        return (<BlindSuper />)
      case urlRoutes.blindGlass:
        return (<BlindGlass />)
      case urlRoutes.portaDeslizante:
        return (<PortaDeslizante />)
      case urlRoutes.portaDupla:
        return (<PortaDupla />)
      case urlRoutes.portaPivotante:
        return (<PortaPivotante />)
      case urlRoutes.cofres:
        return (<Cofres />)
      case urlRoutes.passaVolume:
        return (<PassaVolumes />)
      case urlRoutes.portaCofre:
        return (<PortaCofre />)
      case urlRoutes.outrosProjetos:
        return (<OutrosProjetos />)
      default:
        return (<StyledH2>Pagina não encontrada</StyledH2>)
    }
  }

  return (
    <Container>
      {renderPage()}
    </Container>
  )
}

export default DefaultPage
