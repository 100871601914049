import styled from '@emotion/styled'

export const StyledContainer = styled.section`
  background-color: var(--color-hover-black-dark);
  display: flex;
  font-size: 14px;
  padding: 30px 0;
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;

  div {
    padding: 15px 5px;
    width: 100%;
    text-align: center;
    color: var(--color-gray-light);
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    img {
      max-width: 150px;
      display: block;
      margin: 0 auto;
    }

    &:last-of-type {
      span {
        font-size: 11px;
        margin-top: 10px;
        display: block;
        width: 100%;
      }
    }

    h3 {
      color: var(--color-primary);
      font-weight: 600;
      margin-bottom: 6px;
      font-size: 20px;
      width: 100%;
    }

    p {
      margin-bottom: 3px;
      width: 100%;
    }

    &:first-of-type {
      span {
        margin-top: 10px;
        display: block;
        width: 100%;

        a {
          margin: 0 10px;

          i {
            margin-right: 5px;
          }

          &:hover {
            color: var(--color-primary);
          }
        }
      }
    }
  }

  @media (min-width: 940px) {
    div {
      width: 33%;
    }
  }
`
