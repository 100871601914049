export const urlCompany = '/empresa/'
export const urlProducts = '/produtos/'
export const urlProjects = '/projetos-especiais/'

export const urlRoutes = {
  sobreNos: 'sobre-nos',
  parceiros: 'parceiros',
  blindPlus: 'modelo-blind-plus',
  blindSuper: 'modelo-blind-super',
  blindGlass: 'modelo-blind-glass',
  portaDeslizante: 'porta-deslizante',
  portaDupla: 'porta-dupla',
  portaPivotante: 'porta-pivotante',
  cofres: 'cofres',
  passaVolume: 'passa-volume',
  portaCofre: 'porta-cofre',
  outrosProjetos: 'outros',
  clientes: 'clientes',
  contato: 'contato'
}

export const menuItems = [
  {
    name: 'Home',
    url: '/'
  }, {
    name: 'Empresa',
    url: `${urlCompany}${urlRoutes.sobreNos}`,
    // subMenu: [{
    //   name: 'Sobre a Empresa',
    //   url: `${urlCompany}${urlRoutes.sobreNos}`,
    // }, {
    //   name: 'Parceiros',
    //   url: `${urlCompany}${urlRoutes.parceiros}`,
    // }]
  }, {
    name: 'Produtos',
    url: null,
    subMenu: [{
      name: 'Portas de Segurança',
      url: null,
      subMenu: [{
        name: 'Modelo Blind Plus',
        url: `${urlProducts}${urlRoutes.blindPlus}`,
      }, {
        name: 'Modelo Blind Super',
        url: `${urlProducts}${urlRoutes.blindSuper}`,
      }, {
        name: 'Modelo Blind Glass',
        url: `${urlProducts}${urlRoutes.blindGlass}`,
      }, {
        name: 'Porta Deslizante',
        url: `${urlProducts}${urlRoutes.portaDeslizante}`,
      }, {
        name: 'Porta Dupla',
        url: `${urlProducts}${urlRoutes.portaDupla}`,
      }, {
        name: 'Porta Pivotante',
        url: `${urlProducts}${urlRoutes.portaPivotante}`,
      }]
    }, {
      name: 'Cofres',
      url: `${urlProducts}${urlRoutes.cofres}`,
    }, {
      name: 'Passa Volumes',
      url: `${urlProducts}${urlRoutes.passaVolume}`,
    }, {
      name: 'Porta Cofre',
      url: `${urlProducts}${urlRoutes.portaCofre}`,
    }]
  }, {
    name: 'Projetos Especiais',
    url: `${urlProjects}${urlRoutes.outrosProjetos}`,
  }, {
    name: 'Clientes',
    url: `/${urlRoutes.clientes}`,
  }
]
