import React from 'react'
import BreadCrumb from '@/utils/Breadcrumb'
import { portaDeslizanteAlbum } from '@/utils/AlbumsPhotos'
import { StyledContainer } from './styles'
import Albums from '@/components/Albums'

const PortaDeslizante = () => {

  return (
    <>
      <BreadCrumb msg="Porta Deslizante" />
      <StyledContainer>
        <article className='format-style squad'>
          <h4>Folha de Porta:</h4>
          <p>Construída em chapas de aço SAE 1020 (uma de cada lado), preenchida internamente com longarinas de aço SAE 1020, com aplicação de fundo anticorrosivo e isolante termo-acústico (poliestireno). Estrutura sólida, sem pontos vulneráveis para a introdução de alavancas e alto grau de proteção contra arrombamentos mecânicos.</p>

          <h4>Sistema de Trancamento:</h4>
          <p>Efetuado por fechadura importada de alta segurança, com cilindro a prova de michas, mecanismo de travamento composto por 05 bolts de travamento (04 pontos frontais móveis e 01 Superior). Seu funcionamento assemelha-se ao funcionamento de uma porta convencional, onde ao acionar a chave, movimenta-se os 05 bolts de uma só vez. A porta quando fechada assemelha-se a uma porta normal, mantendo a discrição do ambiente. <br /> Nível II</p>

          <h4>Itens de série:</h4>
          <ul>
            <li>Pivôs com rolamentos</li>
            <li>Bolts de travamento em aço inox</li>
            <li>Fechadura com chave de cópia restrita</li>
            <li>Ferragens cromadas</li>
            <li>Bolt superior</li>
          </ul>

          <h4>Acabamento:</h4>
          <p>MDF revestindo as chapas de aço (onde desta maneira a porta fica com aspecto de porta normal de madeira).<br />
          Com aplicação de fundo primer, pintura ou padrão madeira.<br />
          Porta acompanha batente de aço carbono estrutural dobrado, o qual complementa toda a segurança da porta devido seu alto reforço estrutural, acompanhado de uma instalação condizente com o nível de proteção da porta.</p>

          <h4>Dimensões:</h4>
          <ul>
            <li>Sob medida</li>
          </ul>

          <h4>Trilho:</h4>
          <p>Trilho semelhante ao de portas de shopping.<br />
          Carros transportadores de altura ajustável com roldanas em nylon injetadas sobre rolamentos protegidos de giro silencioso.<br />
          Perfil em alumínio extrudado com diversos tipos de acabamento e cores.</p>

          <h4>Imagens do Produto</h4>

          <Albums photos={portaDeslizanteAlbum} />

          <h4>Vídeos demonstrativos</h4>
          <div className='iframes'>
            <iframe src="https://www.youtube.com/embed/KiLoS9bGr50?rel=0&amp;controls=0&amp;showinfo=0" allowFullScreen="allowfullscreen" />
          </div>
        </article>
      </StyledContainer>
    </>
  )
}

export default PortaDeslizante
