import styled from '@emotion/styled'

export const StyledContainer = styled.section`
  padding: 50px 30px;

  .iframes {
    background-color: var(--color-secondary);
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    margin-bottom: 50px;

    iframe {
      height: 300px;
      width: 100%;
      margin-bottom: 10px;

      @media (min-width:  720px) {
        width: calc(50% - 10px);
        margin: 5px;
      }

      @media (min-width:  1333px) {
        height: 360px;
      }
    }
  }
`
