import React from 'react'
import BreadCrumb from '@/utils/Breadcrumb'
import { blindPlusAlbum } from '@/utils/AlbumsPhotos'
import { StyledContainer } from './styles'
import Albums from '@/components/Albums'

const BlindPlus = () => {

  return (
    <>
      <BreadCrumb msg="Modelo Blind Plus" />
      <StyledContainer>
        <article className='format-style squad'>
          <h3>Porta de segurança mod. Blind Plus ( Espessura 62mm):</h3>
          <h4>Folha de Porta:</h4>
          <p>Construída em chapas de aço SAE 1020 (uma de cada lado), preenchida internamente com longarinas de aço SAE 1020, com aplicação de fundo anticorrosivo e isolante termo-acústico (poliestireno). Estrutura sólida, sem pontos vulneráveis para a introdução de alavancas e alto grau de proteção contra arrombamentos mecânicos.</p>

          <h4>Sistema de Trancamento:</h4>
          <p>Efetuado por fechadura importada de altíssima segurança, com cilindro especial, mecanismo de travamento composto por 14 bolts de travamento (10 pontos frontais móveis e 04 bloqueios fixos na parte lateral junto aos pivôs de sustentação). Seu funcionamento assemelha-se ao funcionamento de uma porta convencional, onde ao acionar a chave, movimenta-se os 09 bolts de uma só vez acionados pela chave e um bolt auxiliar c/ trava manual interna, a qual permite seu acionamento de modo independente do sistema de travamento acionado pela chave, o qual somente pode ser aberto por quem está do lado interno, mesmo que haja uma chave pelo lado externo, a porta somente será aberta com a liberação do mesmo.</p>
          <p>A porta quando fechada assemelha-se a uma porta normal, mantendo a discrição do ambiente.</p>

          <h4>Itens de série:</h4>
          <ul>
            <li>Pivôs com rolamentos</li>
            <li>Bolts de travamento em aço inox</li>
            <li>Ferragens cromadas</li>
            <li>Bolt superior</li>
            <li>Escudo protetor de cilindro</li>
          </ul>

          <h4>Opcionais:</h4>
          <ul>
            <li>Fechadura elétrica tipo solenóide</li>
            <li>Acabamento frizado em MDF</li>
            <li>Revestimento externo da porta com aço inoxidável</li>
            <li>Revestimento da base da porta em aço inoxidável</li>
            <li>Fechadura (Biometria, Key APP e Senha)</li>
          </ul>

          <h4>Acabamento:</h4>
          <p>MDF revestindo as chapas de aço (onde desta maneira a porta fica com aspecto de porta normal de madeira).</p>
          <p>Com aplicação de fundo primer, pintura ou padrão madeira.</p>
          <p>Porta acompanha batente de aço carbono estrutural dobrado, o qual complementa toda a segurança da porta devido seu alto reforço estrutural, acompanhado de uma instalação condizente com o nível de proteção da porta.</p>

          <h4>Dimensões:</h4>
          <ul>
            <li>2100 x 700 mm</li>
            <li>2100 x 800 mm</li>
            <li>2100 x 900 mm</li>
            <li>Sob medida</li>
          </ul>

          <h4>Imagens do Produto</h4>
          <Albums photos={blindPlusAlbum} />

          <h4>Vídeos demonstrativos</h4>
          <div className='iframes'>
            <iframe src="https://www.youtube.com/embed/q7_KaTxvbyQ?rel=0&amp;controls=0&amp;showinfo=0" allowFullScreen="allowfullscreen" />
            <iframe src="https://www.youtube.com/embed/iWy-z5XwgLU?rel=0&amp;controls=0&amp;showinfo=0" allowFullScreen="allowfullscreen" />
          </div>

        </article>
      </StyledContainer>
    </>
  )
}

export default BlindPlus
