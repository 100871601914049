import styled from '@emotion/styled'

export const StyledSection = styled.section`
  min-height: 100%;

  &.transparent {
    background-color: transparent;
  }

  video {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1000;
    overflow: hidden;
  }

  @media (max-width: 979px) {
    min-height: 100%;
  }
`

export const StyledArticles = styled.article`
  border-top: 1px solid var(--color-primary);
  display: flex;
  max-height: 400px;
  overflow: hidden;
  background: rgb(238,238,238);
  background: linear-gradient(90deg, rgba(238,238,238,1) 0%, rgba(51,51,51,1) 87%);
  padding: 40px 0;
  align-items: center;
  justify-content: space-between;

  &:nth-of-type(even) {
    background: linear-gradient(-90deg, rgba(238,238,238,1) 0%, rgba(68,68,68,1) 87%);

    & > div {
      & > div {
        transform: skewX(16deg);

        & > div {
          transform: skewX(-16deg) scale(1.5);
        }
      }

      h2, h4 {
        text-align: right;
      }
    }
  }

  @media (max-width: 780px) {
    flex-wrap: wrap;
    max-height: initial;
    justify-content: center;
    gap: 40px;
    background: linear-gradient(180deg, rgba(238,238,238,1) 0%, rgba(51,51,51,1) 87%);

    &:nth-of-type(even) {
      background: linear-gradient(0deg, rgba(238,238,238,1) 0%, rgba(68,68,68,1) 87%);

      & > div {
        h2, h4 {
          text-align: center;
        }
      }
    }
  }
`

export const StyledText = styled.div`
  display: block;
  width: 50%;
  padding: 0 40px;

  h2 {
    font-size: 40px;
    margin-bottom: 10px;
  }

  h4 {
    margin-bottom: 10px;
    line-height: 23px;
  }

  a {
    color: var(--color-primary);

    &.button {
      width: max-content;
      color: var(--color-secondary);
    }
  }

  @media (max-width: 780px) {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
  }

  @media (max-width: 500px) {
    h2 {
      font-size: 28px;
    }
  }
`

export const StyledImageContainer = styled.div`
  display: flex;

  & > div {
    border: 5px solid #222;
    transform: skewX(-16deg);
    overflow: hidden;
    height: 279px;
    width: 172px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -5px;

    & > div {
      background-size: cover;
      width: 100%;
      height: 100%;
      transform: skewX(16deg) scale(1.5);
    }
  }
`
