import React from 'react'
import BreadCrumb from '@/utils/Breadcrumb'
import {
  cofreAlbum,
  montagemCofreAlbum,
  montagemCofreArmasAlbum,
  montagemCofreComercialAlbum,
  montagemCofreResidencialAlbum,
  montagemCofreExpositivoAlbum
} from '@/utils/AlbumsPhotos'
import { StyledContainer } from './styles'
import Albums from '@/components/Albums'

const Cofres = () => {

  return (
    <>
      <BreadCrumb msg="Cofres" />
      <StyledContainer>
        <article className='format-style squad'>
          <h2>COFRE FORTE ESPECIAL LINHA PREMIUM</h2>
          <h4>Composição da folha de Porta do cofre:Composição da folha de Porta do cofre:</h4>
          <p>Construída em chapas de aço SAE 1020 e placas de aço inox (protegendo as fechaduras).</p>
          <ul>
            <li>Chapa de aço SAE 1020 + inox nas áreas de ataque;</li>
            <li>Perfis Z;</li>
            <li>Camada refratária que dificulta a ação de abrasivos, brocas e serras diamantadas;</li>
            <li>01 chapa de aço carbono SAE 1020;</li>
            <li>Mecanismo sobreposto com 06 bolts em aço especial;</li>
            <li>Perfis de aço ;</li>
            <li>01 chapa de aço SAE 1020 (recobrindo o mecanismo ) .</li>
          </ul>

          <h4>Sistema de Trancamento:</h4>
          <p>Efetuado por fechadura tipo gorja com sistema anti micha. Ao girar a chave libera-se o mecanismo que será acionado por um Timão. Ao girarmos o timão movimentamos simultaneamente 08 pontos de travamento, complementando o sistema 6 bloqueios fixos serão soldados na parte dos pivôs os quais são introduzidos no batente de aço quando a mesma estiver fechada. Para uma segurança adicional será instalado uma fechadura eletrônica motorizada que impede a abertura do mecanismo de travamento quando esta estiver acionada, necessitando também de uma senha para a abertura da porta</p>
          <p>Espessura Total: 140mm <br /> Sentido de abertura: Abrindo para fora e para a direita</p>

          <h4>Itens de série:</h4>
          <ul>
            <li>Camada de concreto de alta performance que dificulta a ação de ferramentas perfurantes;</li>
            <li>Exclusivo sistema anti abertura (relocks);</li>
            <li>Timão de acionamento do mecanismo com dispositivo de alívio em seu eixo (caso seja excedido o torque normal de acionamento o mesmo se quebra mantendo a porta inviolável);</li>
            <li>Fechadura eletrônica com retardo;</li>
            <li>Fechadura gorja (mesmo com a senha a porta somente abrirá com esta fechadura aberta);</li>
            <li>Pivôs especiais, com núcleo de aço inoxidável e rolamentos.</li>
          </ul>

          <h4>Corpo do cofre:</h4>
          <p>Composto por dupla camada de aço com chapas de diferentes espessuras, formando uma camada real de aço à partir de 6mm, tendo uma camada de concreto de alta dureza e várias barras de aço soldadas entre chapas fazendo a união do corpo externo com o corpo interno. As chapas e junções são totalmente soldadas entre si através de solda tipo mig, inexistindo locais de pega para a introdução de alavancas.</p>

          <h4>Opcionais:</h4>
          <ul>
            <li>Revestimento de aço inoxidável parcial ou total;</li>
            <li>Resistente a abrasivos composta de argamassa especial com alto grau de resistência a abrasivos com dureza de 100mpa.</li>
          </ul>

          <h4>Acabamento</h4>
          <p>Pintura automotiva cor cinza ou preto.</p>

          <h4>Tipos de cofres:</h4>
          <p>A Blindap Sistema de segurança é uma empresa extremamente versátil e destaca-se principalmente por sua capacidade de criação de produtos de acordo com a necessidade de cada cliente. <br />
          Em nosso portfólio de produtos constam:</p>

          <h4>Cofre para Armas:</h4>
          <p>Feitos em dois tamanhos ou personalizados.</p>
          <p>
            Modelo Grande: <br />
            1.500mm de altura x 600mm de largura x 600mm de profundidade
          </p>
          <p>
            Modelo Médio: <br />
            1.100mm de altura x 500mm de largura x 500mm de profundidade
          </p>
          <p>
            Ou Sob Medida
          </p>

          <h4>Imagens do Produto</h4>

          <ul>
            <li>Cofres de altíssima segurança com porta única - linha premium;</li>
            <li>Cofres de alta segurança com porta única - linha Super Blind;</li>
            <li>Cofres duplos;</li>
            <li>Cofres com boca de lobo;</li>
            <li>Cofres de embutir;</li>
            <li>Cofre para armas;</li>
            <li>Cofres esconderijo.</li>
          </ul>
          <Albums photos={cofreAlbum} />

          <h4>Montagem de cofre in loco</h4>
          <ul>
            <li>Evita custos de transporte e içamentos;</li>
            <li>Mantém a discrição do equipamento;</li>
            <li>Permite a montagem em qualquer ambiente preparado para </li>recebê-lo;
          </ul>
          <Albums photos={montagemCofreAlbum} />

          <h4>Cofres para Armas</h4>
          <Albums photos={montagemCofreArmasAlbum} />

          <h4>Cofres Comerciais de Alta Performance</h4>
          <p>Feitos sob medida em qualquer tamanho.</p>
          <Albums photos={montagemCofreComercialAlbum} />

          <h4>Cofres Residenciais Personalizados</h4>
          <Albums photos={montagemCofreResidencialAlbum} />

          <h4>Cofres Expositores com Vidros Blindados</h4>
          <Albums photos={montagemCofreExpositivoAlbum} />

          <h4>Vídeos demonstrativos</h4>
          <div className='iframes'>
            <iframe src="https://www.youtube.com/embed/_fEW8kaPT_4?rel=0&amp;showinfo=0" allowFullScreen="allowfullscreen" />
            <iframe src="https://www.youtube.com/embed/xEbUpesEd60?rel=0&amp;showinfo=0" allowFullScreen="allowfullscreen" />
            <iframe src="https://www.youtube.com/embed/l5M3Bv5vItw?rel=0&amp;showinfo=0" allowFullScreen="allowfullscreen" />
            <iframe src="https://www.youtube.com/embed/zhUZnCR-_v4?rel=0&amp;showinfo=0" allowFullScreen="allowfullscreen" />
            <iframe src="https://www.youtube.com/embed/DdSqtK3dx2M?rel=0&amp;showinfo=0" allowFullScreen="allowfullscreen" />
            <iframe src="https://www.youtube.com/embed/TPmE10uoHWU?rel=0&amp;showinfo=0" allowFullScreen="allowfullscreen" />
            <iframe src="https://www.youtube.com/embed/4Gg6uS7sJx8?rel=0&amp;showinfo=0" allowFullScreen="allowfullscreen" />
          </div>
        </article>
      </StyledContainer>
    </>
  )
}

export default Cofres
