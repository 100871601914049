import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Logo from '@/imgs/LOGO.png'
import { menuItems } from './components/menuItems'
import {
  StyledMenu,
  StyledLogo,
  StyledContainer,
  StyledContact,
  StyledMenuMobile,
  StyledModal
} from './styles'
import Modal from '../Modal'

const Menu = (props) => {
  const {
    isMobile,
  } = props

  const [showMenu, setShowMenu] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const renderSubmenu = (el, i) => {
    if (!el.url) {
      return (
        <li key={i}>
          <span>
            {el.name}
            {isMobile ? 
              (<i className="fas fa-chevron-down" />) :
              (<i className="fas fa-chevron-left" />)}
            </span>
          <ul>
            {el.subMenu.map((el, index) => renderSubmenu(el, index))}
          </ul>
        </li>
      )
    }

    return (
      <li key={i} onClick={handleMenuClick}><Link to={el.url}>{el.name}</Link></li>
    )
  }

  const renderMenu = (item, index) => {
    if (!item.url) {
      return (
        <li key={index}>
          <span>{item.name} <i className="fas fa-chevron-down" /></span>
          <ul>
            {item.subMenu.map((el, i) => renderSubmenu(el, i))}
          </ul>
        </li>
      )
    }

    return (
      <li key={index} onClick={handleMenuClick}><Link to={item.url}>{item.name}</Link></li>
    )
  }

  const handleMenuClick = () => {
    setShowMenu(!showMenu)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const contactClick = () => {
    setShowModal(true)
  }

  const renderModal = () => (
    <Modal size="md" closeModal={closeModal}>
      <StyledModal>
        <h2>Fale com a Blindap</h2>
        <div>
          <h3>Contato</h3>
          <span>
            <div>
              <a href="https://wa.me/5511998394552" rel="noreferrer" target="_blank">
                <i className="fab fa-whatsapp-square" />
              </a>
              <a href="tel:11998394552" rel="noreferrer" target="_blank">
                <i className="fas fa-mobile-alt" />
              </a>
              <a href="mailto:blinda@blindap.com.br">
                <i className="fas fa-envelope" />
              </a>
              <a href="https://www.instagram.com/blindap_seg/" rel="noreferrer" target="_blank">
                <i className="fa-brands fa-instagram" />
              </a>
            </div>
            <a href="tel:11998394552" rel="noreferrer" target="_blank">
              <i className="fas fa-mobile-alt" />
              (11) 99839-4552
            </a>
          </span>
          <span>
            <a href="tel:1145498465">
              <i className="fas fa-phone-alt" />
              (11) 4549-8465
            </a>
          </span>
          <span>
            <a href="mailto:blinda@blindap.com.br">
              <i className="fas fa-envelope" />
              blindap@blindap.com.br
            </a>
          </span>
        </div>
        <div>
          <h3>Local</h3>
          <p>Jardim Sônia Maria</p>
          <p>São Paulo / Brasil</p>
          <h3 className='margin'>Horario de Funcionamento</h3>
          <p>De Segunda à Sexta</p>
          <p>Das 08:00 às 17:30 hrs.</p>
        </div>
      </StyledModal>
    </Modal>
  )

  return (
    <StyledContainer>
      {showModal && renderModal()}
      <div className='squad'>
        <StyledLogo>
          <a href="/">
            <img src={Logo} className='app-logo' alt='logo' />
          </a>
        </StyledLogo>
        {!isMobile &&  (
          <StyledMenu>
            <ul>
              {menuItems.map((item, index) => renderMenu(item, index))}
              <li onClick={contactClick}>
                <span>Contato</span>
              </li>
            </ul>
          </StyledMenu>
        )}
        <StyledContact>
          <span>
            <a href="https://wa.me/5511998394552" rel="noreferrer" target="_blank">
              <i className="fab fa-whatsapp" />
              (11) 99839-4552
            </a>
          </span>
          <span>
            <a href="tel:1145498465">
              <i className="fas fa-phone-alt" />
              (11) 4549-8465
            </a>
          </span>
          <span>
            <a href="mailto:blinda@blindap.com.br">
              <i className="fas fa-envelope" />
              blindap@blindap.com.br
            </a>
          </span>
        </StyledContact>
        {isMobile && (
          <StyledMenuMobile onClick={handleMenuClick}>
            <span>
              <i className="fas fa-bars" />
            </span>
          </StyledMenuMobile>
        )}
        {showMenu && isMobile && (
          <StyledMenu className='mobile'>
            <ul>
              {menuItems.map((item, index) => renderMenu(item, index))}
              <li onClick={contactClick}>
                <span>Contato</span>
              </li>
            </ul>
          </StyledMenu>
        )}
      </div>
    </StyledContainer>
  )
}

Menu.propTypes = {
  isMobile: PropTypes.bool.isRequired
}

export default Menu
