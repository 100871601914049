import styled from '@emotion/styled'

export const StyledContainer = styled.section`
  padding: 20px;

  iframe {
    width: 560px;
    height: 315px;
  }

  div {
    &.topo {
      display: flex;

      p {
        width: calc(100% - 560px);
        padding-right: 20px;
      }
    }

    &.baixo {
      display: flex;

      div {
        width: 33%;
        padding: 0 30px;
      }

      @media (max-width: 720px) {
        display: block;

        div {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    div {
      &.topo {
        display: block;
        p {
          dispaly: block;
          width: 100%;
        }
      }

      iframe {
        display: block;
        width: auto;
        height: auto;
      }
    }
  }
`

export const StyledH2 = styled.h2`
  font-size: 40px;
  padding: 100px;
  text-align: center;
`
