import React from 'react'
import videoURL from '@/videos/bgSite.mp4'
import {
  StyledSection,
  StyledArticles,
  StyledText,
  StyledImageContainer
} from './styles'

const Home = () => (
  <>
    <StyledSection className='transparent'>
      <video className='background-video' loop autoPlay muted>
        <source src={videoURL} type='video/mp4' />
        Your browser does not support the video tag.
      </video>
    </StyledSection>
    <StyledSection>
      <StyledArticles>
        <StyledText>
          <h2>Porta Cofre</h2>
          <h4>Se quer uma opção segura e eficiente para sua residência ou comércio contra arrombamentos e roubos, portas e cofres blindados são uma boa escolha.</h4>

          <a className='button' href="/produtos/porta-cofre">
            <i className="fa-solid fa-plus" />
            Ver Mais
          </a>
        </StyledText>
        <StyledImageContainer>
          <div>
            <div style={{ backgroundImage: `url('/imgs/conteudo/porta-cofre1.jpg')` }}/>
          </div>
          <div>
            <div style={{ backgroundImage: `url('/imgs/conteudo/porta-cofre2.jpg')` }}/>
          </div>
          <div>
            <div style={{ backgroundImage: `url('/imgs/conteudo/porta-cofre4.jpg')` }}/>
          </div>
        </StyledImageContainer>
      </StyledArticles>
      <StyledArticles>
        <StyledImageContainer>
          <div>
            <div style={{ backgroundImage: `url('/imgs/conteudo/porta-blindada1.jpg')` }}/>
          </div>
          <div>
            <div style={{ backgroundImage: `url('/imgs/conteudo/porta-blindada2.jpg')` }}/>
          </div>
          <div>
            <div style={{ backgroundImage: `url('/imgs/conteudo/porta-blindada3.jpg')` }}/>
          </div>
        </StyledImageContainer>
        <StyledText>
          <h2>Porta Blindada</h2>
          <h4>Quer saber mais sobre esses produtos? Veja nossos modelos: <a href="/produtos/modelo-blind-super">Blind Super</a>, <a href="/produtos/modelo-blind-plus">Blind Plus</a>, <a href="/produtos/modelo-blind-glass">Blind Glass</a>, <a href="/produtos/porta-pivotante">Porta Pivotante</a>, <a href="/produtos/porta-deslizante">Porta Deslizante</a> e <a href="/produtos/porta-dupla">Porta Dupla</a>.</h4>

        </StyledText>
      </StyledArticles>
      <StyledArticles>
        <StyledText>
          <h2>Cofres</h2>
          <h4>Totalmente personalizados, de acordo com as suas necessidades, para armazenamento de itens valiosos. Podem ser discretos ou mesmo para exibição, quer saber mais?</h4>

          <a className='button' href="/produtos/cofres">
            <i className="fa-solid fa-plus" />
            Ver Mais sobre Cofres
          </a>
        </StyledText>
        <StyledImageContainer>
          <div>
            <div style={{ backgroundImage: `url('/imgs/conteudo/cofre1.jpg')` }}/>
          </div>
          <div>
            <div style={{ backgroundImage: `url('/imgs/conteudo/cofre2.jpg')` }}/>
          </div>
          <div>
            <div style={{ backgroundImage: `url('/imgs/conteudo/cofre3.jpg')` }}/>
          </div>
        </StyledImageContainer>
      </StyledArticles>
    </StyledSection>
  </>
)

export default Home
