import styled from '@emotion/styled'

export const StyledAlbum = styled.div`
  background-color: var(--color-secondary);
  padding: 15px;
  display: grid;
  grid-gap: 15px;
  margin-bottom: 50px;

  figure {
    width: 100%;
    margin: 0;
    padding: 10px;
    background-color: var(--color-hover-black-light);
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    img {
      width: auto;
      max-width: 100%;
      margin: 0 auto;
      max-height: 400px;
    }

    figcaption {
      color: var(--color-bg-dark);
      padding: 5px 0 0;
    }
  }

  @media (min-width: 580px) {
    grid-template-columns: repeat(2, calc(${100 / 2}% - ${15 / 2}px));
  }

  @media (min-width: 680px) {
    grid-template-columns: repeat(3, calc(${100 / 3}% - ${15 * 2 / 3}px));
  }

  @media (min-width: 980px) {
    grid-template-columns: repeat(4, calc(${100 / 4}% - ${15 * 3 / 4}px));
  }

  @media (min-width: 1240px) {
    grid-template-columns: repeat(5, calc(${100 / 5}% - ${15 * 4 / 5}px));
  }

  @media (min-width: 1320px) {
    grid-template-columns: repeat(6, calc(${100 / 6}% - ${15 * 5 / 6}px));
  }
`

export const StyledImagContainer = styled.div`
  width: 100%;
  padding: 20px 5px;
  position: relative;
  height: 100%;
  margin-bottom: 30px;

  .image-gallery-content {
    .image-gallery-slide img {
      width: 100%;
      height: auto;
      min-height: 200px;
    }

    &.fullscreen {
      .image-gallery-slide {
        height: 100vh;
        display: flex !important;
        align-items: center;

        img {
          height: 100vh;
        }
      }
    }
  }

  .image-gallery-slide-wrapper {
    button {
      border: none;
    }
  }

  .image-gallery-slide.center{
    z-index: 3;
  }

  .image-gallery-fullscreen-button {
    bottom: auto;
    top: 0;
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    position: absolute;
    z-index: 4;
    color: var(--color-light);
    font-size: 25px;

    &.active {
      top: 0;
    }

    &:hover {
      color: var(--color-primary);
    }
  }

  .image-gallery-icon:hover {
    color: var(--color-primary);
  }
`
