import React from 'react'
import BreadCrumb from '@/utils/Breadcrumb'
import { portaCofreAlbum } from '@/utils/AlbumsPhotos'
import { StyledContainer } from './styles'
import Albums from '@/components/Albums'

const PortaCofre = () => {

  return (
    <>
      <BreadCrumb msg="Porta Cofre" />
      <StyledContainer>
        <article className='format-style squad'>
          <h4>Composição da folha de Porta:</h4>
          <p>Construída em chapas de aço SAE 1020, chapa de inox e placas de aço inox (protegendo as fechaduras).</p>
          <ul>
            <li>01 chapa de aço SAE 1020 de 1/8”+ 1/8 inóx;</li>
            <li>Perfis Z;</li>
            <li>Camada Química Anti maçarico = 8mm;</li>
            <li>Camada refratária que dificulta a ação de abrasivos, brocas e serras diamantadas = 52mm;</li>
            <li>01 chapa de aço carbono SAE 1020 de 1/8;</li>
            <li>Mecanismo sobreposto com 13 bolts de 44 mm em aço especial;</li>
            <li>Perfis de aço ;</li>
            <li>01 chapa de aço SAE 1020 de 2mm ( recobrindo o mecanismo ) .</li>
          </ul>

          <h4>Sistema de Trancamento:</h4>
          <p>Efetuado por fechadura tipo gorja com sistema anti micha. Ao girar a chave libera-se o mecanismo que será acionado por um Timão. Ao girarmos o timão movimentamos simultaneamente 07 pontos de travamento, complementando o sistema 6 bloqueios fixos serão soldados na parte dos pivôs os quais são introduzidos no batente de aço quando a mesma estiver fechada. Para uma segurança adicional será instalado uma fechadura eletrônica motorizada que impede a abertura do mecanismo de travamento quando esta estiver acionada, necessitando também de uma senha para a abertura da porta</p>

          <h4>Dimensões de folha:</h4>
          <ul>
            <li>Tamanho especial de folha: 1.000 x 600mm</li>
            <li>Espessura Total: 140mm</li>
            <li>Sentido de abertura: Abrindo para fora e para a direita</li>
          </ul>

          <h4>Opcionais inclusos:</h4>
          <ul>
            <li>Proteção química anti maçarico composta de uma liga a base de enxofre, breu e betume onde caso haja uma ação com maçarico a mesma responde com uma lavareda no sentido contrário ao fogo do maçarico, emitindo na hora uma fumaça tóxica que dilata as vias respiratórias causando sufocamento, náuseas e vômitos, não chega a matar mas torna insuportável a permanência no local;</li>
            <li>Resistente a abrasivos composta de argamassa especial feita com cimento CP5, dramix (lâminas de aço dobradas nas extremidades que dão consistência a argamassa), óxido de alumínio (corindon) em várias granas (material que complementa a argamassa proporcionando altíssima dureza de 60 à 70 Mpa), com alto grau de resistência a abrasivos</li>
            <li>Exclusivo sistema anti abertura, (relocks);</li>
            <li>Timão de acionamento do mecanismo com dispositivo de alívio em seu eixo (caso seja excedido o torque normal de acionamento o mesmo se quebra mantendo a porta inviolável);</li>
            <li>Fechadura gorja (mesmo com a senha a porta somente abrirá com esta fechadura aberta)</li>
            <li>Pivôs especiais com rolamentos.</li>
          </ul>

          <h4>Acabamento:</h4>
          <p>Esmalte grafitado anti corrosivo cinza</p>

          <h4>Nível Balístico:</h4>
          <p>Acima de nível V</p>

          <h4>Imagens do Produto</h4>
          <Albums photos={portaCofreAlbum} />

          <h4>Vídeos demonstrativos</h4>
          <div className='iframes'>
            <iframe src="https://www.youtube.com/embed/PRF57O3Sm3g?rel=0&amp;controls=0&amp;showinfo=0" allowFullScreen="allowfullscreen" />
            <iframe src="https://www.youtube.com/embed/R_4qZVgiw0Q?rel=0&amp;controls=0&amp;showinfo=0" allowFullScreen="allowfullscreen" />
            <iframe src="https://www.youtube.com/embed/0Q3YIRdCkBg?rel=0&amp;controls=0&amp;showinfo=0" allowFullScreen="allowfullscreen" />
            <iframe src="https://www.youtube.com/embed/GHuJI5pkEC0?rel=0&amp;controls=0&amp;showinfo=0" allowFullScreen="allowfullscreen" />
            <iframe src="https://www.youtube.com/embed/dN3JAn4tZ18?rel=0&amp;controls=0&amp;showinfo=0" allowFullScreen="allowfullscreen" />
          </div>
        </article>
      </StyledContainer>
    </>
  )
}

export default PortaCofre
