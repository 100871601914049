import React from 'react'
import BreadCrumb from '@/utils/Breadcrumb'
import { blindagempcsAlbum, outrosProjetosAlbum, outrosProjetosEspecial } from '@/utils/AlbumsPhotos'
import { StyledContainer } from './styles'
import Albums from '@/components/Albums'

const OutrosProjetos = () => {

  return (
    <>
      <BreadCrumb msg="Produtos Diferenciados" />
      <StyledContainer>
        <article className='format-style squad'>
          <h4>BlindLock</h4>
          <p>Produto com ótima relação custo benefício para quem quer adicionar segurança em uma porta comum já instalada sendo exclusivo para agregar resistência à arrombamentos mecânicos (Pé de cabras, marretas, talhadeiras e alavancas diversas).</p>
          <p>Sistema de fácil instalação com trava auxiliar sobreposta pelo lado interno da porta que efetua o travamento por travas de aço maciças onde as mesmas ficam invisíveis pelo lado externo.</p>

          <h4>Caixa Forte, Fechamento de Segurança e Blindagem de Ambiente</h4>
          <div className='iframes'>
            <iframe src="https://www.youtube.com/embed/R_4qZVgiw0Q?rel=0&amp;showinfo=0" allowFullScreen="allowfullscreen" />
            <iframe src="https://www.youtube.com/embed/etIUXUFtmq4?rel=0&amp;showinfo=0" allowFullScreen="allowfullscreen" />
          </div>

          <h4>Cofre Expositor</h4>
          <Albums photos={outrosProjetosEspecial} />

          <h4>Fechaduras Especiais</h4>
          <p>Sistema de trancamento inovador e ótima opção para agregar mais segurança à uma porta já instalada. Este sistema é sobreposto e quando acionado, três linguetas simultaneamente irão travar a porta. Sistema de acionamento manual ou elétrico.</p>
          <Albums photos={outrosProjetosAlbum} />

          <h4>Segurança física para PC`s e Servidores</h4>
          <p>Esta proteção é um produto exclusivo e patenteado pela <b>Blindap</b> e proporciona a segurança do gabinete do computador, permitindo o acesso apenas às pessoas autorizadas.<br />
          Proteção contra o roubo do gabinete e de seus componentes internos, não restringindo o uso. <b>Exclusiva proteção para DVR`s</b>. <br />
          Proteja seu equipamento e sua informação.</p>
          <Albums photos={blindagempcsAlbum} />
        </article>
      </StyledContainer>
    </>
  )
}

export default OutrosProjetos
