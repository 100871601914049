import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from '@/components/Modal'
import ImageGallery from 'react-image-gallery'
import { StyledAlbum, StyledImagContainer } from './styles'

const Albums = (props) => {
  const { photos } = props
  const [showModal, setShowModal] = useState(false)
  const [imageShow, setImageShow] = useState(null)

  const closeModal = () => {
    setShowModal(false)
    setImageShow(null)
  }

  const onClickImage = (index) => {
    const foto = photos.map((foto) => {
      return {original: foto.url}
    })

    setShowModal(true)
    setImageShow({
      foto,
      index
    })
  }

  const renderModal = () => (
    <Modal
      closeModal={closeModal}
      size='lg'
    >
      <StyledImagContainer>
        <ImageGallery
          items={imageShow.foto}
          showThumbnails={false}
          infinite={false}
          showPlayButton={false}
          startIndex={imageShow.index}
        />
      </StyledImagContainer>
    </Modal>
  )

  const renderAlbum = () => {
    return photos.map((foto, index) => {
      return (
        <figure key={index} onClick={() => onClickImage(index)}>
          <img src={foto.url} />
          <figcaption>{foto.description}</figcaption>
        </figure>
      )
    })
  }

  return (
    <>
      {showModal && renderModal()}
      <StyledAlbum>
        {renderAlbum()}
      </StyledAlbum>
    </>
  )
}

Albums.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default Albums
