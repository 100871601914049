import React from 'react'
import BreadCrumb from '@/utils/Breadcrumb'
import { StyledContainer } from './styles'

const AboutUs = () => (
  <>
    <BreadCrumb msg="Sobre a Empresa" />
    <StyledContainer>
      <article className='format-style squad'>
        <div className='topo'>
          <p>A Blindap Sistemas de Segurança é uma empresa especializada na criação de produtos para proteção contra arrombamentos, atuante ha mais de 20 anos, com equipe de profissionais com vasta experiência no ramo de segurança, treinados para atender as necessidades específicas de cada cliente proporcionando soluções personalizadas, onde cada produto é bem dizer exclusivo para cada cliente, garantindo resistência e alta qualidade em todas as opções de serviços que proporcionamos. A Blindap está entre as empresas mais conceituadas do segmento de segurança arquitetônica personalizada no Brasil. Desenvolvemos produtos de segurança customizados, desde o projeto inicial até a instalação final do produto, utilizando recursos tecnológicos da mais alta qualidade. A Blindap proporciona diversas soluções em segurança, entre elas: Portas de Segurança, Porta Cofre, Cofres de alta segurança, Cofre oculto, Vidros de segurnaça, Segurança de servidores e Desktops , Passa-Valores, Kits de Segurança e Projetos Especiais.</p>
          <iframe
            src='https://www.youtube.com/embed/YfAJVCWw4uQ?rel=0&amp;controls=0&amp;showinfo=0'
            allowFullScreen
          />
        </div>
        <hr />
        <div className='baixo'>
          <div>
            <h6>Missão</h6>
            <p>Fornecer soluções de segurança personalizada aos nossos paceiros com excelência, tecnologia avançada e com melhor refino em acabamento, proporcionando segurança e tranquilidade</p>
          </div>

          <div>
            <h6>Visão</h6>
            <p>Ser uma referencia mundial em soluções de segurança, com produtos de alta tecnologia, confiança e qualidade.</p>
          </div>

          <div>
            <h6>Valores</h6>
            <ul>
              <li>Inovação</li>
              <li>Qualidade</li>
              <li>Tecnologia</li>
              <li>Segurança</li>
              <li>Ética</li>
              <li>Transparência</li>
            </ul>
          </div>
        </div>
      </article>
    </StyledContainer>
  </>
)


export default AboutUs
