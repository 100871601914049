import styled from '@emotion/styled'

export const StyledContainer = styled.header`
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 200;
  transition: all 0.3s ease-out;
  background: var(--color-hover-black-dark);
  padding: 10px;
  align-items: center;

  @media (min-width: 1024px) {
    padding: 10px 30px;
  }

  .squad {
    display: flex;
    max-width: 1333px;
    margin: 0 auto;
  }
`

export const StyledMenu = styled.nav`
  display: flex;
  align-items: center;
  width: 100%;

  & > ul {
    text-align: center;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-left: 10px;

    & > li {
      position: relative;
      cursor: pointer;

      a, span {
        color: #eee;
        display: flex;
        width: 100%;
        height: 100%;
        padding: 15px 5px;
        font-size: 13px;
        text-transform: uppercase;
        align-items: center;

        i {
          margin-left: 5px;
        }
      }

      & > ul {
        width: 200%;
        position: absolute;
        top: -80%;
        left: 0;
        opacity: 0;
        transition: all .2s;
        background-color: var(--color-bg-dark);
        height: 0;
        overflow: hidden;
        z-index: 201;
        border-top: 1px solid var(--color-primary);
        box-shadow: 3px 3px 3px 3px var(--color-hover-black-light);

        & > li {
          width: 100%;
          border-bottom: 1px solid var(--color-hover-black-dark);

          & > ul {
            width: 100%;
            position: absolute;
            top: 0;
            left: -80%;
            opacity: 0;
            transition: all .2s;
            background-color: var(--color-gray-dark);
            height: 0;
            overflow: hidden;
            z-index: 202;
            border-top: 1px solid var(--color-primary);
            box-shadow: 3px 3px 3px 3px var(--color-hover-black-light);

            li {
              border-bottom: 1px solid var(--color-bg-dark);

              &:hover {
                a {
                  color: var(--color-primary);
                }
              }
            }
          }

          &:hover {
            & > ul {
              left: -100%;
              opacity: 1;
              height: auto;
              overflow: initial;
              top: -1px;
            }

            & > a, & > span {
              color: var(--color-primary);
            }
          }
        }
      }

      &:hover {
        & > ul {
          top: 100%;
          opacity: 1;
          height: auto;
          overflow: initial;
        }

        & > a, & > span {
          color: var(--color-primary);
        }
      }
    }
  }

  &.mobile {
    position: absolute;
    right: 0;
    top: 100%;
    width: 50%;
    background-color: var(--color-hover-black-dark);
    border-top: 1px solid var(--color-primary);

    & > ul {
      width: 100%;
      flex-wrap: wrap;

      & > li {
        width: 100%;

        a, span {
          padding: 10px;
        }

        & > ul {
          opacity: 1;
          height: auto;
          position: relative;
          width: calc(100% - 20px);
          margin: 0 auto;

          & > li {
            ul {
              opacity: 1;
              height: auto;
              position: relative;
              width: calc(100% - 20px);
              margin: 0 auto;
              top: 0;
              left: 0;
            }
          }
        }
      }
    }

    @media (max-width: 580px) {
      width: 100%;
    }
  }
`

export const StyledLogo = styled.div`
  display: flex;
  align-items: center;

  img {
    max-width: 180px;
  }

  @media (min-width: 480px) {
    img { 
      max-width: 240px;
    }
  }
`

export const StyledContact = styled.div`
  display: none;

  @media (min-width: 580px) {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    width: calc(100% - 290px);

    span {
      display: block;
      margin: 3px 10px;
      text-align: center;
      width: 100%;

      a {
        color: var(--color-gray-light);
        font-size: 14px;

        &:hover {
          color: var(--color-primary);
        }

        i {
          margin-right: 5px;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    max-width: 222px;
  }
`

export const StyledMenuMobile = styled.span`
  display: flex;
  align-items: center;
  margin-left: auto;

  span {
    width: 50px;
    height: 50px;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: var(--color-hover-light);
    color: var(--color-primary);

    &:hover {
      background-color: var(--color-primary);
      color: var(--color-bg-dark);
    }
  }

`

export const StyledModal = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 40px 0;
  justify-content: center;

  h2 {
    width: 100%;
    text-align: center;
    margin-bottom: 20px !important;
    border-bottom: 2px solid var(--color-primary);
    padding-bottom: 10px;
  }

  h3 {
    color: var(--color-primary) !important;
    margin-bottom: 10px !important;

    &.margin {
      margin-top: 30px;
    }
  }

  & > div {
    width: 50%;
    padding: 20px;

    span {
      display: block;
      margin-bottom: 5px;

      a {
        color: var(--color-bg-dark);

        i {
          margin-right: 8px;
        }
      }

      div {
        border-bottom: 1px solid var(--color-primary);
        margin-bottom: 10px;
        padding-bottom: 10px;

        a {
          font-size: 36px;
          margin-right: 20px;

          &:hover {
            color: #000000;
          }

          .fa-whatsapp-square {
            color: #1ad03f;

            &:hover {
              color: #099124;
            }
          }

          .fa-envelope {
            color: var(--color-primary);

            &:hover {
              color: #0c5f97;
            }
          }
        }
      }
    }

    p {
      margin-bottom: 5px;
    }

    @media (max-width: 620px) {
      width: 100%;
    }
  }

`
