import React, {useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from '@/components/Home'
import Menu from '@/components/Menu'
import Footer from '@/components/Footer'
import Company from '@/components/Company'
import DefaultPage from '@/components/DefaultPage'
import Clients from '@/components/Clients'

const INNER_WIDTH_PARAM = 1024

const DefaultRoute = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= INNER_WIDTH_PARAM)

  useEffect(() => {
    window.addEventListener('resize', () => {
      const widthW = window.innerWidth

      if (widthW <= INNER_WIDTH_PARAM) {
        return setIsMobile(true)
      }

      return setIsMobile(false)
    })
  }, [])

  return (
    <Router>
      <Menu isMobile={isMobile} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route exact path="/empresa/:id" element={<Company />} />
        <Route path="/produtos/:id" element={<DefaultPage />} />
        <Route path="/projetos-especiais/:id" element={<DefaultPage />} />
        <Route path="/clientes" element={<Clients />} />
      </Routes>
      <Footer />
    </Router>
  )
}

export default DefaultRoute


