import React from 'react'
import { useParams } from 'react-router-dom'
import Container from '@/utils/Container'
import { urlRoutes } from '@/components/Menu/components/menuItems'
import AboutUs from './pages/AboutUs'
import { StyledH2 } from './pages/styles'
// import Partners from './pages/Partners'

const Company = () => {
  const params = useParams()

  const renderPage = () => {
    switch (params.id) {
      case urlRoutes.sobreNos:
        return (<AboutUs />)

      // case urlRoutes.parceiros:
      //   return (<Partners />)

      default:
        return (<StyledH2>Pagina não encontrada</StyledH2>)
    }
  }

  return (
    <Container>
      {renderPage()}
    </Container>
  )
}

export default Company
