import React from 'react'
import Logo from '@/imgs/LOGO.png'
import { StyledContainer } from './styles'

const Footer = () => (
  <StyledContainer>
    <div>
      <h3>Horario de Funcionamento</h3>
      <p>De Segunda à Sexta</p>
      <p>Das 08:00 às 17:30 hrs.</p>
      <span>
        <a href="https://wa.me/5511998394552" rel="noreferrer" target="_blank">
          <i className="fab fa-whatsapp" />
          (11) 99839-4552
        </a>
        |
        <a href="tel:1145498465">
          <i className="fas fa-phone-alt" />
          (11) 4549-8465
        </a>
        <span className='clear' />
        <a href="https://www.instagram.com/blindap_seg/" rel="noreferrer" target="_blank">
          <i className="fa-brands fa-instagram" />
          Instagram
        </a>
      </span>
    </div>
    <div>
      <h3>Local</h3>
      <p>Jardim Sônia Maria</p>
      <p>São Paulo / Brasil</p>
    </div>
    <div>
      <img src={Logo} className='app-logo' alt='logo' />
      <span>© Todos os direitos reservados - 2016</span>
    </div>
  </StyledContainer>
)

export default Footer
