import React from 'react'
import BreadCrumb from '@/utils/Breadcrumb'
import { passaVolumesAlbum } from '@/utils/AlbumsPhotos'
import { StyledContainer } from './styles'
import Albums from '@/components/Albums'

const PassaVolumes = () => {

  return (
    <>
      <BreadCrumb msg="Passa Volumes" />
      <StyledContainer>
        <article className='format-style squad'>
          <p>Equipamentos para passagem de volumes, feitos em aço, os quais possibilitam o recebimento de encomendas diversas, de acordo com o dimensional de cada equipamento, sem haver o contato físico com o entregador, mantendo a integridade de quem está recebendo a encomenda, impedindo qualquer coação, decorrente deste tipo de evento.</p>

          <h4>Imagens do Produto</h4>
          <Albums photos={passaVolumesAlbum} />
        </article>
      </StyledContainer>
    </>
  )
}

export default PassaVolumes
