const url = '/imgs/'

export const partnersAlbum = [
  {
    url: `${url}parceiros/parceiro2.jpg`,
    description: 'Rodolfo (Beluni), Rúbens (Chaveiro 2000), Henrique (Blindap) e Marcelo (Samsung).'
  }, {
    url: `${url}parceiros/parceiro3.jpg`,
    description: 'Banner divulgação Blindap'
  }, {
    url:  `${url}parceiros/parceiro4.jpg`,
    description: 'Porta blindada exposta no show room'
  }, {
    url: `${url}parceiros/parceiro5.jpg`,
    description: 'Visão interna loja Chaveiro 2000'
  }
]

export const blindPlusAlbum = [
  {
    url: `${url}blindPlus/blindplus1.jpg`,
    description: null,
  }, {
    url: `${url}blindPlus/blindplus2.jpg`,
    description: null,
  }, {
    url: `${url}blindPlus/blindplus3.jpg`,
    description: null,
  }, {
    url: `${url}blindPlus/blindplus4.jpg`,
    description: null,
  }, {
    url: `${url}blindPlus/blindplus5.jpg`,
    description: null,
  }, {
    url: `${url}blindPlus/blindplus6.jpg`,
    description: null,
  }, {
    url: `${url}blindPlus/blindplus7.jpg`,
    description: null,
  }, {
    url: `${url}blindPlus/blindplus8.jpg`,
    description: null,
  }, {
    url: `${url}blindPlus/blindplus9.jpg`,
    description: null,
  }, {
    url: `${url}blindPlus/blindplus10.jpg`,
    description: null,
  }, {
    url: `${url}blindPlus/blindplus11.jpg`,
    description: null,
  }, {
    url: `${url}blindPlus/blindplus12.jpg`,
    description: null,
  }
]

export const blindGlassAlbum = [
  {
    url: `${url}blindGlass/blindglass1.jpg`,
    description: null,
  }, {
    url: `${url}blindGlass/blindglass2.jpg`,
    description: null,
  }, {
    url: `${url}blindGlass/blindglass3.jpg`,
    description: null,
  }, {
    url: `${url}blindGlass/blindglass4.jpg`,
    description: null,
  }, {
    url: `${url}blindGlass/blindglass5.jpg`,
    description: null,
  }, {
    url: `${url}blindGlass/blindglass6.jpg`,
    description: null,
  }, {
    url: `${url}blindGlass/blindglass8.jpg`,
    description: null,
  }, {
    url: `${url}blindGlass/blindglass10.jpg`,
    description: null,
  }, {
    url: `${url}blindGlass/blindglass11.jpg`,
    description: null,
  }, {
    url: `${url}blindGlass/blindglass12.jpg`,
    description: null,
  }, {
    url: `${url}blindGlass/blindglass13.jpg`,
    description: null,
  }
]

export const blindSuperAlbum = [
  {
    url: `${url}blindSuper/blindsuper1.jpg`,
    description: null,
  }, {
    url: `${url}blindSuper/blindsuper2.jpg`,
    description: null,
  }, {
    url: `${url}blindSuper/blindsuper3.jpg`,
    description: null,
  }, {
    url: `${url}blindSuper/blindsuper4.jpg`,
    description: null,
  }, {
    url: `${url}blindSuper/blindsuper5.jpg`,
    description: null,
  }, {
    url: `${url}blindSuper/blindsuper6.jpg`,
    description: null,
  }, {
    url: `${url}blindSuper/blindsuper7.jpg`,
    description: null,
  }, {
    url: `${url}blindSuper/blindsuper8.jpg`,
    description: null,
  }, {
    url: `${url}blindSuper/blindsuper9.jpg`,
    description: null,
  }, {
    url: `${url}blindSuper/blindsuper10.jpg`,
    description: null,
  }, {
    url: `${url}blindSuper/blindsuper11.jpg`,
    description: null,
  }
]

export const cofreAlbum = [
  {
    url: `${url}cofres/cofre1.jpg`,
    description: 'Cofre Premium',
  }, {
    url: `${url}cofres/cofre2.jpg`,
    description: 'Cofre Duplo',
  }, {
    url: `${url}cofres/cofre3.jpg`,
    description: 'Cofre Duplo',
  }, {
    url: `${url}cofres/cofre4.jpg`,
    description: 'Cofre de Embutir',
  }, {
    url: `${url}cofres/cofre5.jpg`,
    description: 'Cofre de Embutir',
  }, {
    url: `${url}cofres/cofre6.jpg`,
    description: 'Cofre de Embutir com alimentação por fonte elétrica',
  }, {
    url: `${url}cofres/cofre7.jpg`,
    description: 'Cofre para armas',
  }, {
    url: `${url}cofres/cofre8.jpg`,
    description: 'Cofre para armas - travas',
  }, {
    url: `${url}cofres/cofre9.jpg`,
    description: 'Cofre para armas - interno',
  }, {
    url: `${url}cofres/cofre10.jpg`,
    description: 'Cofre Steel Box',
  }, {
    url: `${url}cofres/cofre11.jpg`,
    description: 'Cofre Steel Box',
  }, {
    url: `${url}cofres/cofre12.jpg`,
    description: 'Cofre Boca de Lobo revestido em Inox',
  }, {
    url: `${url}cofres/cofre13.jpg`,
    description: 'Parceiros especializados para transporte de cofres pesados',
  }, {
    url: `${url}cofres/cofre14.jpg`,
    description: 'Sistema de ancoragem ao piso',
  }, {
    url: `${url}cofres/cofre15.jpg`,
    description: 'Compartimento para entrada de cédulas com chaves',
  }, {
    url: `${url}cofres/cofre16.jpg`,
    description: 'Cofre Boca de Lobo',
  }, {
    url: `${url}cofres/cofre17.jpg`,
    description: 'Cofre Boca de Lobo',
  }, {
    url: `${url}cofres/cofre18.jpg`,
    description: 'Cofre Boca de Lobo',
  }, {
    url: `${url}cofres/cofre19.jpg`,
    description: 'Preparado para montagem',
  }, {
    url: `${url}cofres/cofre20.jpg`,
    description: 'Chumbamento do cofre no local',
  }, {
    url: `${url}cofres/cofre21.jpg`,
    description: 'Chumbamento do cofre no piso',
  }, {
    url: `${url}cofres/cofre22.jpg`,
    description: 'Chumbamento do cofre no piso',
  }, {
    url: `${url}cofres/cofre23.jpg`,
    description: 'Armário a receber o cofre',
  }, {
    url: `${url}cofres/cofre24.jpg`,
    description: 'Cofre a ser fixado no móvel',
  }, {
    url: `${url}cofres/cofre25.jpg`,
    description: 'Cofre no local',
  }
]

export const montagemCofreAlbum = [
  {
    url: `${url}cofres/montagemcofre1.jpg`,
    description: 'Montagem de módulos individuais',
  }, {
    url: `${url}cofres/montagemcofre2.jpg`,
    description: 'Formando a composição do corpo do cofre',
  }, {
    url: `${url}cofres/montagemcofre3.jpg`,
    description: 'Junção feita por soldas especiais que mantém a integridade do corpo do cofre',
  }, {
    url: `${url}cofres/montagemcofre4.jpg`,
    description: 'Subdivisão do corpo do cofre',
  }, {
    url: `${url}cofres/montagemcofre5.jpg`,
    description: 'Fixação do cofre à parede',
  }, {
    url: `${url}cofres/montagemcofre6.jpg`,
    description: 'Colocação da porta',
  }, {
    url: `${url}cofres/montagemcofre7.jpg`,
    description: 'Camuflagem dentro de um armário',
  }, {
    url: `${url}cofres/montagemcofre8.jpg`,
    description: 'Funcionamento similar ao de qualquer cofre',
  }, {
    url: `${url}cofres/montagemcofre9.jpg`,
    description: 'Dimensões especiais',
  }, {
    url: `${url}cofres/montagemcofre10.jpg`,
    description: 'Projetado para caber dentro do móvel já existente',
  }, {
    url: `${url}cofres/montagemcofre11.jpg`,
    description: 'Mantendo a discrição do ambiente',
  }
]

export const montagemCofreArmasAlbum = [
  {
    url: `${url}cofres/cofreArma-1.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofreArma-2.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofreArma-3.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofreArma-4.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofreArma-5.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofreArma-6.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofreArma-7.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofreArma-9.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofreArma-10.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofreArma-11.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofreArma-12.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofreArma-13.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofreArma-14.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofreArma-15.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofreArma-16.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofreArma-17.jpg`,
    description: '',
  }
]

export const montagemCofreComercialAlbum = [
  {
    url: `${url}cofres/cofres-comerciais-1.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofres-comerciais-2.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofres-comerciais-3.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofres-comerciais-4.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofres-comerciais-5.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofres-comerciais-6.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofres-comerciais-7.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofres-comerciais-8.jpg`,
    description: '',
  }
]

export const montagemCofreExpositivoAlbum = [
  {
    url: `${url}cofres/cofres-expositivos-1.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofres-expositivos-2.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofres-expositivos-3.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofres-expositivos-4.jpg`,
    description: '',
  }
]

export const montagemCofreResidencialAlbum = [
  {
    url: `${url}cofres/cofre-residencial-personalizado-14.jpg`,
    description: 'Cofre residencial revestido em couro legítimo',
  }, {
    url: `${url}cofres/cofre-piso-1.jpg`,
    description: 'Cofre de piso',
  }, {
    url: `${url}cofres/cofre-piso-2.jpg`,
    description: 'Cofre de piso',
  }, {
    url: `${url}cofres/cofre-piso-3.jpg`,
    description: 'Cofre de piso',
  }, {
    url: `${url}cofres/cofre-residencial-personalizado-1.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofre-residencial-personalizado-2.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofre-residencial-personalizado-3.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofre-residencial-personalizado-4.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofre-residencial-personalizado-5.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofre-residencial-personalizado-6.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofre-residencial-personalizado-7.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofre-residencial-personalizado-8.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofre-residencial-personalizado-9.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofre-residencial-personalizado-10.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofre-residencial-personalizado-11.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofre-residencial-personalizado-12.jpg`,
    description: '',
  }, {
    url: `${url}cofres/cofre-residencial-personalizado-13.jpg`,
    description: '',
  }
]

export const blindagempcsAlbum = [
  {
    url: `${url}outrosProjetos/blindagempcs1.jpg`,
    description: null,
  }, {
    url: `${url}outrosProjetos/blindagempcs2.jpg`,
    description: null,
  }, {
    url: `${url}outrosProjetos/blindagempcs3.jpg`,
    description: null,
  }, {
    url: `${url}outrosProjetos/blindagempcs4.jpg`,
    description: null,
  }, {
    url: `${url}outrosProjetos/blindagempcs5.jpg`,
    description: null,
  }, {
    url: `${url}outrosProjetos/blindagempcs6.jpg`,
    description: null,
  }
]
export const outrosProjetosAlbum = [
  {
    url: `${url}outrosProjetos/outrosprojetos1.jpg`,
    description: null,
  }, {
    url: `${url}outrosProjetos/outrosprojetos2.jpg`,
    description: null,
  }, {
    url: `${url}outrosProjetos/outrosprojetos3.jpg`,
    description: null,
  }, {
    url: `${url}outrosProjetos/outrosprojetos4.jpg`,
    description: null,
  }, {
    url: `${url}outrosProjetos/outrosprojetos5.jpg`,
    description: null,
  }
]

export const outrosProjetosEspecial = [
  {
    url: `${url}outrosProjetos/proj-especial-1.jpg`,
    description: null,
  }, {
    url: `${url}outrosProjetos/proj-especial-2.jpg`,
    description: null,
  }, {
    url: `${url}outrosProjetos/proj-especial-3.jpg`,
    description: null,
  }, {
    url: `${url}outrosProjetos/proj-especial-4.jpg`,
    description: null,
  }, {
    url: `${url}outrosProjetos/proj-especial-5.jpg`,
    description: null,
  }
]

export const passaVolumesAlbum = [
  {
    url: `${url}passaValores/passavalores10.jpg`,
    description: '',
  }, {
    url: `${url}passaValores/passavalores11.jpg`,
    description: '',
  }, {
    url: `${url}passaValores/passavalores12.jpg`,
    description: '',
  }, {
    url: `${url}passaValores/passavalores1.jpg`,
    description: 'M3367S-4507',
  }, {
    url: `${url}passaValores/passavalores2.jpg`,
    description: null,
  }, {
    url: `${url}passaValores/passavalores4.jpg`,
    description: null,
  }, {
    url: `${url}passaValores/passavalores5.jpg`,
    description: null,
  }, {
    url: `${url}passaValores/passavalores6.jpg`,
    description: null,
  }, {
    url: `${url}passaValores/passavalores7.jpg`,
    description: null,
  }, {
    url: `${url}passaValores/passavalores8.jpg`,
    description: null,
  }, {
    url: `${url}passaValores/passavalores9.jpg`,
    description: null,
  }
]

export const portaCofreAlbum = [
  {
    url: `${url}portaCofre/portacofre9.jpg`,
    description: null,
  }, {
    url: `${url}portaCofre/portacofre10.jpg`,
    description: null,
  }, {
    url: `${url}portaCofre/portacofre11.jpg`,
    description: null,
  }, {
    url: `${url}portaCofre/portacofre12.jpg`,
    description: null,
  }, {
    url: `${url}portaCofre/portacofre1.jpg`,
    description: null,
  }, {
    url: `${url}portaCofre/portacofre2.jpg`,
    description: null,
  }, {
    url: `${url}portaCofre/portacofre3.jpg`,
    description: null,
  }, {
    url: `${url}portaCofre/portacofre4.jpg`,
    description: null,
  }, {
    url: `${url}portaCofre/portacofre5.jpg`,
    description: null,
  }, {
    url: `${url}portaCofre/portacofre6.jpg`,
    description: null,
  }, {
    url: `${url}portaCofre/portacofre7.jpg`,
    description: null,
  }, {
    url: `${url}portaCofre/portacofre8.jpg`,
    description: null,
  }
]

export const portaDeslizanteAlbum = [
  {
    url: `${url}portaDeslizante/portadeslizante1.jpg`,
    description: null,
  }, {
    url: `${url}portaDeslizante/portadeslizante2.jpg`,
    description: null,
  }, {
    url: `${url}portaDeslizante/portadeslizante3.jpg`,
    description: null,
  }, {
    url: `${url}portaDeslizante/portadeslizante4.jpg`,
    description: null,
  }, {
    url: `${url}portaDeslizante/portadeslizante5.jpg`,
    description: null,
  }
]

export const portaDuplaAlbum = [
  {
    url: `${url}portaDupla/porta-dupla-1.jpg`,
    description: null,
  }, {
    url: `${url}portaDupla/porta-dupla-2.jpg`,
    description: null,
  }, {
    url: `${url}portaDupla/porta-dupla-3.jpg`,
    description: null,
  }, {
    url: `${url}portaDupla/porta-dupla-4.jpg`,
    description: null,
  }, {
    url: `${url}portaDupla/porta-dupla-5.jpg`,
    description: null,
  }, {
    url: `${url}portaDupla/porta-dupla-6.jpg`,
    description: null,
  }, {
    url: `${url}portaDupla/porta-dupla-7.jpg`,
    description: null,
  }
]

export const portaPivotanteAlbum = [
  {
    url: `${url}portaPivotante/porta-pivotante-1.jpg`,
    description: null,
  }, {
    url: `${url}portaPivotante/porta-pivotante-2.jpg`,
    description: null,
  }, {
    url: `${url}portaPivotante/porta-pivotante-3.jpg`,
    description: null,
  }, {
    url: `${url}portaPivotante/porta-pivotante-4.jpg`,
    description: null,
  }, {
    url: `${url}portaPivotante/porta-pivotante-5.jpg`,
    description: null,
  }, {
    url: `${url}portaPivotante/porta-pivotante-6.jpg`,
    description: null,
  }, {
    url: `${url}portaPivotante/porta-pivotante-7.jpg`,
    description: null,
  }, {
    url: `${url}portaPivotante/porta-pivotante-8.jpg`,
    description: null,
  }, {
    url: `${url}portaPivotante/porta-pivotante-9.jpg`,
    description: null,
  }, {
    url: `${url}portaPivotante/porta-pivotante-10.jpg`,
    description: null,
  }, {
    url: `${url}portaPivotante/porta-pivotante-11.jpg`,
    description: null,
  }, {
    url: `${url}portaPivotante/porta-pivotante-12.jpg`,
    description: null,
  }
]

export const clientesAlbum = [
  {
    url: `${url}clientes/cliente1.jpg`,
    description: null,
  }, {
    url: `${url}clientes/cliente2.jpg`,
    description: null,
  }, {
    url: `${url}clientes/cliente3.jpg`,
    description: null,
  }, {
    url: `${url}clientes/cliente4.jpg`,
    description: null,
  }, {
    url: `${url}clientes/cliente5.jpg`,
    description: null,
  }, {
    url: `${url}clientes/cliente6.jpg`,
    description: null,
  }, {
    url: `${url}clientes/logo7.jpg`,
    description: null,
  }, {
    url: `${url}clientes/logo8.jpg`,
    description: null,
  }, {
    url: `${url}clientes/logo9.jpg`,
    description: null,
  }, {
    url: `${url}clientes/logo10.jpg`,
    description: null,
  }, {
    url: `${url}clientes/logo11.jpg`,
    description: null,
  }, {
    url: `${url}clientes/logo13.jpg`,
    description: null,
  }, {
    url: `${url}clientes/logo14.jpg`,
    description: null,
  }, {
    url: `${url}clientes/logo15.jpg`,
    description: null,
  }, {
    url: `${url}clientes/logo16.jpg`,
    description: null,
  }, {
    url: `${url}clientes/logo17.jpg`,
    description: null,
  }, {
    url: `${url}clientes/logo18.jpg`,
    description: null,
  }, {
    url: `${url}clientes/logo19.jpg`,
    description: null,
  }, {
    url: `${url}clientes/logo20.jpg`,
    description: null,
  }, {
    url: `${url}clientes/logo21.jpg`,
    description: null,
  }, {
    url: `${url}clientes/logo22.jpg`,
    description: null,
  }, {
    url: `${url}clientes/logo23.jpg`,
    description: null,
  }, {
    url: `${url}clientes/logo24.jpg`,
    description: null,
  }
]
