import React from 'react'
import BreadCrumb from '@/utils/Breadcrumb'
import { blindGlassAlbum } from '@/utils/AlbumsPhotos'
import { StyledContainer } from './styles'
import Albums from '@/components/Albums'

const BlindGlass = () => {

  return (
    <>
      <BreadCrumb msg="Porta Blind Glass" />
      <StyledContainer>
        <article className='format-style squad'>
          <h4>Folha de Porta:</h4>
          <p>Construída em tubulares de aço SAE 1020  100 x 50mm, formando estrutura perimetral sólida, sem pontos vulneráveis para a introdução de alavancas e alto grau de proteção contra arrombamentos mecânicos. Desenho da porta com alterações dimensionais, repartições e possibilidade de incluir acabamentos especiais no aço.</p>

          <h4>Sistema de Trancamento:</h4>
          <p>Efetuado por fechadura importada de alta segurança, com cilindro especial, mecanismo de travamento composto por 13 bolts de travamento (09 pontos frontais móveis e 04 bloqueios fixos na parte lateral junto aos pivôs de sustentação). Seu funcionamento assemelha-se ao funcionamento de uma porta convencional, onde ao acionar a chave, movimenta-se os 09 bolts de uma só vez. A porta quando fechada assemelha-se a uma porta normal, mantendo a discrição do ambiente.</p>

          <h4>Itens de série:</h4>
          <ul>
            <li>Pivôs com rolamentos</li>
            <li>Bolts de travamento em aço inox</li>
            <li>Ferragens cromadas</li>
            <li>Bolt superior</li>
            <li>Roseta para euro perfil / Escudo protetor de cilindro</li>
          </ul>

          <h4>Opcionais:</h4>
          <ul>
            <li>Fechadura elétrica tipo solenóide</li>
            <li>Acabamento frizado em MDF</li>
            <li>Revestimento externo da porta com aço inoxidável</li>
            <li>Revestimento da base da porta em aço inoxidável</li>
            <li>Fechadura (Biometria, Key APP e Senha)</li>
          </ul>

          <h4>Acabamento:</h4>
          <p>MDF revestindo as chapas de aço (onde desta maneira a porta fica com aspecto de porta normal de madeira).<br />
          Com aplicação de fundo primer, pintura ou padrão madeira.<br />
          Porta acompanha batente de aço carbono estrutural dobrado, o qual complementa toda a segurança da porta devido seu alto reforço estrutural, acompanhado de uma instalação condizente com o nível de proteção da porta.</p>

          <h4>Dimensões:</h4>
          <ul>
            <li>2100 x 700 mm</li>
            <li>2100 x 800 mm</li>
            <li>2100 x 900 mm</li>
            <li>Sob medida</li>
          </ul>

          <h4>Imagens do Produto</h4>

          <Albums photos={blindGlassAlbum} />
        </article>
      </StyledContainer>
    </>
  )
}

export default BlindGlass
